<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
    @click="onClickCallback"
  >
    <div
      :style="style"
      v-dompurify-html="label"
    />
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeLabel',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = 'width: 100%;'
      const dataEditor = this.cell
      const settings = dataEditor.settings
      if (settings.font_size) {
        style += ` font-size: ${settings.font_size}px;`
      }
      if (settings.font_bold) {
        style += ' font-weight: bold;'
      }
      if (settings.font_italic) {
        style += ' font-style: italic;'
      }
      style += ` text-align: ${settings.alignment_horizontal || ''};`
      style += ` vertical-align: ${settings.alignment_vertical || ''};`
      return style
    },
    label() {
      const builder = this.data.ChecklistBuilder
      return builder.getLocalization(this.cell, 'preset_value') || ''
    }
  },
  methods: {
    onClickCallback() {
      if (!this.cell.settings.on_click_callback) {
        return
      }
      const builder = this.data.ChecklistBuilder
      const onCellClicked = builder.getSetting('ON_CELL_CLICKED')
      if (typeof onCellClicked !== 'function') {
        return
      }
      onCellClicked(this.cell)
    }
  }
}
</script>
